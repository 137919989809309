import Select from 'react-select';
import { Formik } from 'formik';

import {
  components,
  MultiValueGenericProps,
  SingleValueProps,
} from 'react-select';

import {
  Box,
  Button as Button1,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  FormErrorMessage,
  Flex,
  Stack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Divider,
  useDisclosure,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Link,
} from '@chakra-ui/react';
import Button from 'Library/Button';

import ScrollToFieldError from 'components/app/ScrollError';
import AtsCraetableSelect from 'components/app/AtsCreatabeSelect';
import AtsEmailBody from 'components/app/Global/Email/AIBody';

import 'react-quill/dist/quill.snow.css';
import 'assets/css/minHquill.css';

import { useEmailsModalServices } from './useEmailsModalServices';
import { EmailsModalProps } from './types';
import FAIcon from 'components/lib/FAIcon';

import { useAIGeneratorServices } from 'components/app/AIGenerator/useAIGeneratorServices';
import TemplateAddModal from 'components/app/Global/TemplateModal';

export default function EmailsModal({
  isOpen,
  onClose,
  id,
  email,
  modalTitle = 'Send Email',
  isSubjectDisabled = false,
  candidate_id,
  job_id,
  allowedCategory,
  isReply,
  idList,
  isBulkEmail,
  emailList,
}: EmailsModalProps) {
  const {
    initialValues,
    replyEmail,
    validationSchema,
    ccBcc,
    populateTemplate,
    clientTemplate,
    template,
    attachments,
    setAttachments,
    setquilValue,
    bcc,
    isLoading,
    setTemplateId,
    handleClose,
    previewOpen,
    setPreviewOpen,
    placholders,
    isOpenAlert,
    onCloseAlert,
    tokenLink,
    errMsg,
    setBccValue,
    setCcValue,
    templateCallback
  } = useEmailsModalServices({
    isOpen,
    onClose,
    email,
    id,
    candidate_id,
    job_id,
    isReply,
    idList,
    isBulkEmail,
    emailList,
  });

  const {
    hasAIGenerate,
    setHasAIGenerate,
    setIsGenerate,
    handleValues,
    handleAIGenerator,
    setIsError,
    isMaximize,
  } = useAIGeneratorServices({ hasType: isReply ? 'Inbox' : 'Candidate' });

  const {
    isOpen: addOpen,
    onOpen: addOnOpen,
    onClose: addOnClose,
  } = useDisclosure();

  // Function to remove email part from label
  const removeEmailFromLabel = (label: string) =>
    label.replace(/<[^>]*>/g, '').trim();

  // Custom SingleValue component
  const SingleValue = (props: SingleValueProps<any>) => {
    return (
      <components.SingleValue {...props}>
        {removeEmailFromLabel(props.data.label)}
      </components.SingleValue>
    );
  };

  // Custom MultiValueLabel component
  const MultiValueLabel = (props: MultiValueGenericProps<any>) => {
    return (
      <components.MultiValueLabel {...props}>
        {removeEmailFromLabel(props.data.label)}
      </components.MultiValueLabel>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={handleClose}
      size="6xl"
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        fontFamily="NunitoSans Regular"
        maxW={{
          '1280px': '1200px', // 1280 x 832
          '1366px': '1200px', // 1366 x 768
          '1400px': '1300px', // 1400 x 900
          '1512px': '1400px', // 1512 x 982
          '1728px': '1500px', // 1728 x 1117
          '1920px': '1500px', // 1920 x 1280
        }}
        w={{
          '1280px': '1200px', // 1280 x 832
          '1366px': '1200px', // 1366 x 768
          '1400px': '1300px', // 1400 x 900
          '1512px': '1400px', // 1512 x 982
          '1728px': '1500px', // 1728 x 1117
          '1920px': '1500px', // 1920 x 1280
        }}
        maxH={{
          '1280px': '550px', // 1280 x 832
          '1366px': '550px', // 1366 x 768
          '1400px': '700px', // 1400 x 900
          '1512px': '700px', // 1512 x 982
          '1728px': '800px', // 1728 x 1117
          '1920px': '800px', // 1920 x 1280
        }}
        h={{
          '1280px': '550px', // 1280 x 832
          '1366px': '550px', // 1366 x 768
          '1400px': '700px', // 1400 x 900
          '1512px': '700px', // 1512 x 982
          '1728px': '800px', // 1728 x 1117
          '1920px': '800px', // 1920 x 1280
        }}
      >
        <ModalHeader
          sx={{
            background: '#F9FAFB',
            borderRadius: '4px 4px 0',
            p: '16px',
            fontSize: '18px',
            lineHeight: '18px',
            fontWeight: 700,
            color: '#2B2D42',
            textAlign: 'left',
          }}
        >
          {modalTitle}
        </ModalHeader>

        {isOpenAlert && (
          <Box>
            <Alert status="error" justifyContent="space-around">
              <AlertIcon />
              <Box width="100%">
                <AlertTitle>Oh snap!</AlertTitle>
                <AlertDescription>{errMsg}</AlertDescription>
                {tokenLink !== '' && (
                  <AlertDescription>
                    <Link href={tokenLink} isExternal>
                      Click to follow this link
                    </Link>
                  </AlertDescription>
                )}
              </Box>
              <CloseButton
                position="relative"
                right={-1}
                top={-3}
                onClick={onCloseAlert}
              />
            </Alert>
          </Box>
        )}

        <ModalBody
          sx={{
            bgColor: '#F9FAFB',
            borderRadius: '0 0 4px 4px',
            p: 0,
            h: '100%',
          }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={replyEmail}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
              errors,
              touched,
            }) => {
              return (
                <form onSubmit={handleSubmit} style={{ height: '100%' }}>
                  <ScrollToFieldError />

                  <Stack spacing={0} justifyContent="space-between" h="inherit">
                    <Flex
                      justifyContent="center"
                      gap="8px"
                      sx={{
                        h: 'inherit',
                        overflow: 'hidden',
                        '& #ai': {
                          overflowY: 'scroll',
                          h: 'inherit',
                        },
                        '& ::-webkit-scrollbar': {
                          display: 'none',
                        },
                      }}
                    >
                      <Stack
                        h="inherit"
                        spacing="8px"
                        sx={{
                          display:
                            hasAIGenerate && isMaximize ? 'none' : 'flex',
                          w: hasAIGenerate !== '' ? '65%' : '100%',
                          m: '0 8px',
                          mr: hasAIGenerate && 0,
                        }}
                      >
                        <Stack
                          spacing="16px"
                          sx={{
                            background: '#fff',
                            borderRadius: '6px',
                            p: '16px',
                          }}
                        >
                          <Flex gap="16px">
                            <FormControl
                              isInvalid={Boolean(!!errors.to && touched.to)}
                            >
                              <FormLabel fontSize="14px" lineHeight="18px">
                                To{' '}
                                <Box as="span" color="caution.800">
                                  *
                                </Box>
                              </FormLabel>
                              <Input
                                id="to"
                                name="to"
                                type="email"
                                variant="outline"
                                value={values.to}
                                onChange={handleChange}
                                isDisabled
                                sx={{
                                  height: '38px',
                                  border: 'none',
                                  borderBottom: '1px solid #E7EDF4',
                                  p: '8px 12px',
                                  fontSize: '14px',
                                }}
                              />
                              <FormErrorMessage>
                                {String(errors.to)}
                              </FormErrorMessage>
                            </FormControl>

                            <FormControl
                              isInvalid={Boolean(!!errors.cc && touched.cc)}
                            >
                              <FormLabel fontSize="14px" lineHeight="18px">
                                Cc
                              </FormLabel>
                              <AtsCraetableSelect
                                id="cc"
                                name="cc"
                                onChange={(event: any) => setCcValue(event)}
                                options={ccBcc}
                                isMulti={true}
                                placeholder="cc"
                                defaultValue={values.cc}
                                components={{ SingleValue, MultiValueLabel }}
                                styles={{
                                  control: (styles: any) => ({
                                    ...styles,
                                    fontSize: '14px',
                                    border: 'none',
                                    borderBottom: '1px solid #E7E9ED',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    boxShadow: 'none',
                                    '&:hover': {},
                                  }),
                                  valueContainer: (styles: any) => ({
                                    ...styles,
                                    maxHeight: '33px',
                                    overflowY: 'scroll',
                                  }),
                                  dropdownIndicator: (styles: any) => ({
                                    ...styles,
                                    color: '#676767',
                                  }),
                                  indicatorSeparator: () => ({
                                    display: 'none',
                                  }),
                                  placeholder: (styles: any) => ({
                                    ...styles,
                                    fontSize: '14px',
                                    color: '#9B9B9B',
                                  }),
                                  multiValue: (styles: any) => ({
                                    ...styles,
                                    background: '#F7FAFC',
                                    border: '1px solid #E2E8F0',
                                    borderRadius: '6px',
                                    '&& div:first-of-type': {
                                      color: '#2B2D42',
                                    },
                                  }),
                                }}
                              />

                              <FormErrorMessage>
                                {String(errors.cc)}
                              </FormErrorMessage>
                            </FormControl>

                            <FormControl
                              isInvalid={Boolean(!!errors.bcc && touched.bcc)}
                            >
                              <FormLabel fontSize="14px" lineHeight="18px">
                                Bcc
                              </FormLabel>
                              <AtsCraetableSelect
                                id="bcc"
                                name="bcc"
                                onChange={(event: any) => setBccValue(event)}
                                options={bcc}
                                isMulti={true}
                                placeholder="bcc"
                                defaultValue={values.bcc}
                                components={{ SingleValue, MultiValueLabel }}
                                styles={{
                                  control: (styles: any) => ({
                                    ...styles,
                                    fontSize: '14px',
                                    border: 'none',
                                    borderBottom: '1px solid #E7E9ED',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    boxShadow: 'none',
                                    '&:hover': {},
                                  }),
                                  valueContainer: (styles: any) => ({
                                    ...styles,
                                    maxHeight: '33px',
                                    overflowY: 'scroll',
                                  }),
                                  dropdownIndicator: (styles: any) => ({
                                    ...styles,
                                    color: '#676767',
                                  }),
                                  indicatorSeparator: () => ({
                                    display: 'none',
                                  }),
                                  placeholder: (styles: any) => ({
                                    ...styles,
                                    fontSize: '14px',
                                    color: '#9B9B9B',
                                  }),
                                  multiValue: (styles: any) => ({
                                    ...styles,
                                    background: '#F7FAFC',
                                    border: '1px solid #E2E8F0',
                                    borderRadius: '6px',
                                    '&& div:first-of-type': {
                                      color: '#2B2D42',
                                    },
                                  }),
                                }}
                              />

                              <FormErrorMessage>
                                {String(errors.bcc)}
                              </FormErrorMessage>
                            </FormControl>
                          </Flex>

                          <FormControl
                            isInvalid={Boolean(
                              !!errors.subject && touched.subject
                            )}
                          >
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <FormLabel fontSize="14px" lineHeight="18px">
                                Subject{' '}
                                <Box as="span" color="caution.800">
                                  *
                                </Box>
                              </FormLabel>
                              <Button1
                                leftIcon={
                                  <FAIcon
                                    iconName={
                                      !previewOpen ? 'eye' : 'eye-slash'
                                    }
                                  />
                                }
                                variant={'borderless'}
                                onClick={() => {
                                  setPreviewOpen(!previewOpen);
                                }}
                              >
                                Preview
                              </Button1>
                            </Flex>

                            {!previewOpen ? (
                              <Input
                                name="subject"
                                type="text"
                                placeholder="Subject"
                                variant="outline"
                                value={values.subject}
                                onChange={(e: any) => {
                                  setFieldValue('subject', e.target.value);
                                }}
                                disabled={isSubjectDisabled}
                                sx={{
                                  height: '38px',
                                  border: 'none',
                                  borderBottom: '1px solid',
                                  borderColor: '#E7E9ED',
                                  p: '8px 12px',
                                  fontSize: '14px',
                                }}
                              />
                            ) : (
                              <Input
                                name="subject"
                                type="text"
                                placeholder="Subject"
                                variant="outline"
                                value={placholders(values.subject)}
                                onChange={() => {}}
                                disabled
                                sx={{
                                  height: '38px',
                                  border: 'none',
                                  borderBottom: '1px solid',
                                  borderColor: '#E7E9ED',
                                  p: '8px 12px',
                                  fontSize: '14px',
                                }}
                              />
                            )}
                            <FormErrorMessage>
                              {String(errors.subject)}
                            </FormErrorMessage>
                          </FormControl>
                        </Stack>

                        <Stack
                          h="inherit"
                          sx={{
                            position: 'relative',
                            bgColor: '#FFF',
                            borderRadius: '6px',
                            p: '16px',
                            overflowY: 'scroll',
                            '& ::-webkit-scrollbar': {
                              display: 'none',
                            },
                          }}
                        >
                          <FormControl
                            h="inherit"
                            isInvalid={Boolean(!!errors.body && touched.body)}
                          >
                            {!previewOpen ? (
                              <AtsEmailBody
                                value={values.body}
                                onChange={(e: any) => {
                                  setFieldValue('body', e);
                                }}
                                height={220}
                                hasAIGenerate={hasAIGenerate}
                                setHasAIGenerate={setHasAIGenerate}
                                setIsGenerate={setIsGenerate}
                                handleValues={handleValues}
                                setIsError={setIsError}
                                subject={values.subject}
                                body={values.body}
                                allowedCategory={allowedCategory}
                                attachments={attachments}
                                setAttachments={setAttachments}
                                isHideAttach={isReply}
                              />
                            ) : (
                              <AtsEmailBody
                                value={placholders(values.body)}
                                onChange={() => {}}
                                disabled
                                height={220}
                                hasAIGenerate={hasAIGenerate}
                                setHasAIGenerate={setHasAIGenerate}
                                setIsGenerate={setIsGenerate}
                                handleValues={handleValues}
                                setIsError={setIsError}
                                subject={values.subject}
                                body={values.body}
                                allowedCategory={allowedCategory}
                                attachments={attachments}
                                setAttachments={setAttachments}
                                isHideAttach={isReply}
                              />
                            )}
                            <FormErrorMessage>
                              {String(errors.body)}
                            </FormErrorMessage>
                          </FormControl>
                        </Stack>
                      </Stack>

                      {handleAIGenerator(setFieldValue)}
                    </Flex>

                    <FormControl
                      sx={{
                        position: 'absolute',
                        top: '6px',
                        right: '8px',
                        height: '40px',
                        width: '169px',
                      }}
                    >
                      <Select
                        placeholder="Select a Template"
                        isMulti={false}
                        value={clientTemplate}
                        options={template}
                        onChange={(e: any) => {
                          setFieldValue('body', e.body);
                          setFieldValue('subject', e.subject);
                          setAttachments(e.attachments);
                          setTemplateId(e.value);
                          setPreviewOpen(true);
                        }}
                        styles={{
                          control: (styles: any) => ({
                            ...styles,
                            fontSize: '14px',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            boxShadow: '0px 2px 8px 0px #0000000A',
                          }),
                          dropdownIndicator: (styles: any) => ({
                            ...styles,
                            color: '#718096',
                          }),
                          indicatorSeparator: () => ({
                            display: 'none',
                          }),
                          placeholder: (styles: any) => ({
                            ...styles,
                            fontSize: '14px',
                            color: '#2B2D42',
                          }),
                          multiValue: (styles: any) => ({
                            ...styles,
                            background: '#F7FAFC',
                            border: '1px solid #E2E8F0',
                            borderRadius: '6px',
                            '&& div:first-of-type': {
                              color: '#6930CA',
                              fontWeight: 600,
                            },
                          }),
                        }}
                        components={{
                          Option: ({ label, data, innerProps }) => {
                            const isCreate = label === 'Create New Template';
                            return (
                              <div {...innerProps}>
                                {isCreate && <Divider m={0} />}
                                <Text
                                  sx={{
                                    p: '16px 8px',
                                    fontSize: '12px',
                                    color: '#2B2D42',
                                    cursor: 'pointer',
                                    fontWeight: isCreate ? 700 : 400,
                                  }}
                                  onClick={(e) => {
                                    isCreate
                                      ? addOnOpen()
                                      : innerProps.onClick(e);
                                  }}
                                >
                                  {label}
                                </Text>
                              </div>
                            );
                          },
                        }}
                      />
                    </FormControl>

                    <ModalFooter
                      position="sticky"
                      bottom="0"
                      background="#F9F9FB"
                      p="8px"
                      zIndex={2}
                    >
                      <Flex gap="8px">
                        <Button1 onClick={onClose}>Cancel</Button1>
                        <Menu closeOnSelect={true}>
                          <Flex>
                            <Box
                              sx={{
                                button: {
                                  p: '8px 4px',
                                  pl: '8px',
                                  height: '40px',
                                  fontSize: '12px',
                                  borderRight: 'none',
                                  borderRadius: '6px 0 0 6px',
                                },
                              }}
                            >
                              <Button1 type="button">Save As</Button1>
                            </Box>
                            <MenuButton
                              as={Button1}
                              type="button"
                              sx={{
                                p: '8px',
                                height: '40px',
                                fontSize: '12px',
                                borderLeft: 'none',
                                borderRadius: '0 6px 6px 0',
                              }}
                            >
                              |{' '}
                              <Box as="span" ml="10px">
                                <FAIcon iconName="chevron-down" />
                              </Box>
                            </MenuButton>
                          </Flex>
                          {
                            <MenuList
                              fontSize="sm"
                              maxHeight="120px"
                              overflowY="auto"
                            >
                              <MenuItem onClick={() => {}}>
                                Save as a template
                              </MenuItem>
                            </MenuList>
                          }
                        </Menu>
                        <Button1
                          variant="solid"
                          type="submit"
                          isLoading={isLoading}
                        >
                          {isReply ? 'Reply' : 'Send'}
                        </Button1>
                      </Flex>
                    </ModalFooter>
                  </Stack>
                  {addOpen && (
                    <TemplateAddModal
                      isOpen={addOpen}
                      onClose={addOnClose}
                      templateCallback={() => templateCallback(setFieldValue)}
                    />
                  )}
                </form>
              );
            }}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
