import './emailBody.css';

import React, { useRef, useState, useEffect, useCallback } from 'react';
// import * as Quill from 'quill';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Box } from '@chakra-ui/react';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { Color } from '@tiptap/extension-color';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import ListItem from '@tiptap/extension-list-item';
import Link from '@tiptap/extension-link';
import Code from '@tiptap/extension-code';
import Image from '@tiptap/extension-image';
import Youtube from '@tiptap/extension-youtube';
import Document from '@tiptap/extension-document';
import TextStyle from '@tiptap/extension-text-style';
import CustomParagraph from './CustomParagraph';
import Toolbar from './Toolbar';

interface AtsEmailBodyInterface {
  key?: number;
  onChange: any;
  invalid?: boolean;
  initialValue?: string;
  value?: string;
  id?: any;
  height?: any;
  menubar?: boolean;
  placeholder?: string;
  disabled?: boolean;
  refocusTo?: string;
  hasAIGenerate: string;
  setHasAIGenerate: any;
  setIsGenerate: any;
  setIsError: any;
  handleValues: (subject: string, body: string) => void;
  subject: string;
  body: string;
  allowedCategory?: any;
  attachments?: any;
  setAttachments?: any;
  isHideAttach?: boolean;
}

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      borderStyle: {
        default: '2px solid #ced4da',
        parseHTML: (element) =>
          element.style.borderStyle || '2px solid #ced4da',
        renderHTML: (attributes) => {
          return {
            style: `border: ${attributes.borderStyle}`,
          };
        },
      },
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-background-color'),
        renderHTML: (attributes) => {
          return {
            'data-background-color': attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
    };
  },
});

const AtsEmailBody: React.FC<AtsEmailBodyInterface> = ({
  key,
  onChange,
  invalid,
  initialValue,
  value,
  id,
  height = 500,
  menubar,
  placeholder,
  disabled,
  refocusTo,
  hasAIGenerate,
  setHasAIGenerate,
  setIsGenerate,
  setIsError,
  handleValues,
  subject,
  body,
  allowedCategory,
  attachments,
  setAttachments,
  isHideAttach,
}) => {
  const [text, setText] = useState<string | null>(value);
  const [editorHeight, setEditorHeight] = useState(height || 'auto');

  const editorRef = useRef<any>(null);
  const minHeight = height;
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        paragraph: false,
      }),
      CustomParagraph,
      TableRow,
      TableHeader,
      CustomTableCell,
      Document,
      Color,
      Code,
      Underline,
      BulletList,
      Paragraph,
      Text,
      TextStyle,
      OrderedList,
      ListItem,
      Youtube,
      Highlight.configure({ multicolor: true }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Table.configure({
        resizable: true,
        HTMLAttributes: {
          cellSpacing: '0',
          lineHeight: '0',
        },
      }),
      Link.configure({
        openOnClick: false,
        autolink: true,
        protocols: ['https', 'http', 'ftp', 'mailto'],
      }),
      Image.configure({
        inline: true,
      }),
    ],
    content: text,
    editable: !disabled, // Make the editor non-editable if disabled is true
    onUpdate: ({ editor }) => {
      if (!disabled) {
        // Ensure updates happen only when not disabled
        handleTextChange(editor.getHTML());
      }
    },
  });

  useEffect(() => {
    if (value !== text) {
      setText(value);
      editor.commands.setContent(value);
    }
  }, [value]);

  const handleTextChange = (e: any) => {
    if (disabled) return;
    setText(e);

    if (onChange) {
      onChange(e);
    }
    adjustEditorHeight();
  };

  const adjustEditorHeight = useCallback(() => {
    if (editorRef.current) {
      const quill = editorRef.current.getEditor();
      if (quill) {
        const editorContent = quill.root;

        const newHeight = editorContent.scrollHeight;
        setEditorHeight(
          newHeight < minHeight ? `${minHeight}px` : `${newHeight}px`
        );
      }
    }
  }, [minHeight]);

  useEffect(() => {
    adjustEditorHeight();
  }, [adjustEditorHeight]);

  return (
    <Box
      sx={{
        '&& .tiptap.ProseMirror': {
          height: '45vh',
          overflowY: 'scroll',
          padding: '12px 15px',
          marginBottom: '70px',
          outline: 'none',
          color: disabled ? '#828283' : '',
        },
        '&& .tiptap.ProseMirror ProseMirror-focused': {
          border: 'none',
        },
        display: 'inline-block',
        cursor: disabled ? 'not-allowed' : '',
      }}
      w="100%"
    >
      <EditorContent editor={editor} disabled={disabled} />

      <Toolbar
        editor={editor}
        content={text}
        hasAIGenerate={hasAIGenerate}
        setHasAIGenerate={setHasAIGenerate}
        setIsGenerate={setIsGenerate}
        handleValues={handleValues}
        setIsError={setIsError}
        subject={subject}
        body={body}
        allowedCategory={allowedCategory}
        isHideAttach={isHideAttach}
      />
    </Box>
  );
};

export default AtsEmailBody;
